<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Amikor a szerelem fojtogatóvá válik
          </h2>
          <p class="hashText">– Love Bombing</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A love bombing a pszichológiai és érzelmi bántalmazás egy olyan fajtája, amelynek során a másik fél minden tőle telhetőt megtesz annak érdekében, hogy magához láncolja partnerét. Bár úgy tűnhet, hogy ez csak párkapcsolati kereteken belül történhet meg, valójában koránt sincs így: családon belül, vagy baráti kapcsolatokban is ugyanúgy felütheti a fejét ez a fajta „szeretettel való gúzsba kötés”. </p>

                <p class="text">
                Cikkünkben bemutatunk néhány tipikus figyelmeztető jelet, amelyek jelenlétéből a love bombingra következtethetünk:</p>

                <p class="text">
                -	Partnerünk akár már az első találkozás alkalmával kifejezi érzéseit, és lelkesen veti bele magát a jövőről való fantáziálásokba: összeköltözés, esküvő, gyerekek. Amennyiben mi erre nem a ,,megfelelő”, számára kedvező módon reagálunk, esetleg korainak tartjuk mindezt, könnyen sértődés vagy érzelmi bántalmazás kereszttüzében találhatjuk magunkat.</p>
                <p class="text">
                -	Partnerünk, családtagunk minden pillanatban fogni szeretné a kezünket, szeretne a közelünkben lenni, át akar ölelni vagy puszit adni olyan helyzetekben is, amikor ez nem helyénvaló – például nyilvános helyen, könyvbemutatón, vagy egy nagy családi esemény közben. Az irántunk táplált érzelmek túlzott kommunikálása szintén gyakori formája a szerelemmel való bombázásnak.</p>
                <p class="text">
                -	Egy másik intő jel, amikor partnerünk lépten-nyomon ajándékokkal halmoz el minket. Ez eleinte kifejezetten jó érzés lehet, azonban az idő előrehaladtával egyre terhesebb lesz, hiszen a szükségtelen, és nem ritkán drága ajándékozás zavarba ejtő, és gyakran kellemetlenné válhat. Ennek hátterében úgyszintén a behálózás áll, vagyis, hogy a másik fél minél inkább magához láncoljon minket anyagi eszközökkel (is).</p>
                <p class="text">
                -	A love bombing hatása természetesen az online jelenlétünkre is kihathat azáltal, hogy partnerünk lépten-nyomon „megjelöli” a tartalmainkat, jelzi, hogy hozzá tartozunk, jelen van, és mindent figyel. Ellenőrizni szeretné, hogy kivel beszélünk, merre járunk, és kikkel találkozunk – szinte teljes transzparenciát kérve ezzel. </p>
                <p class="text">
                Ilyen esetekben érdekében érdemes lehet a telefonunkat olyan kóddal – esetleg ujjlenyomat-érzékelővel – ellátni, amelyet csak mi ismerünk, így megőrizve privát szféránkat. A közösségi oldalaink bejelentkezési adatait ne adjuk ki, az eszközökről ne felejtsünk el kijelentkezni! Új telefonra váltás esetén töröljük alkalmazásainkat a régi készülékről, valamint ne engedélyezzük a telefonos nyomkövetést, hiszen a love bombinggal élő személy általában visszaél vele. </p>
                <p class="text">
                Fontos, hogy a szerelmi bombázást nem szabad félvállról venni, ha pedig felismertük a helyzetet, szakemberek és támogató családtagok, barátok segíthetnek kilépni a fojtogató kapcsolatból. </p>
            </div>
            </b-col>
            <b-col cols="12" class="order-4 order-lg-1">
              <img src="images/love.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Amikor a szerelem fojtogatóvá válik", 
        desc: "A ,,love bombing”, azaz a szerelemmel való bombázás egy olyan jelenség, amely sokáig észrevétlen, sőt, akár pozitív töltetű is lehet, miközben valójában a bántalmazás egy formájáról beszélünk.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Amikor a szerelem fojtogatóvá válik",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A ,,love bombing”, azaz a szerelemmel való bombázás egy olyan jelenség, amely sokáig észrevétlen, sőt, akár pozitív töltetű is lehet, miközben valójában a bántalmazás egy formájáról beszélünk."        
    })
  }
}
</script>